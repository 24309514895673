import React from "react"
import {
	Grid,
	TextField,
	InputAdornment,
	Typography,
	Button,
} from "@material-ui/core/"
import { useTranslation } from "react-i18next"

const AddElectricity = ({ values, handleInput, inputList, setInputList }) => {
	const { t } = useTranslation()
	function getCountryElecAverage() {
		const electricityAverages = require("../../../../public/data/average-electricity-country.json") //with path
		const list = { ...inputList }
		list["electricity"][0]["average"] = 1
		if (values.country !== "") {
			list["electricity"][0]["electricity"] =
				electricityAverages[values.country].avg

			setInputList(list)
			handleInput(
				{
					target: {
						category: "electricity",
						value: electricityAverages[values.country].avg,
						name: "electricity",
					},
				},
				0
			)
		} else {
			list["electricity"][0]["electricity"] = electricityAverages["World"].avg
			setInputList(list)
			handleInput(
				{
					target: {
						category: "electricity",
						value: electricityAverages["World"].avg,
						name: "electricity",
					},
				},
				0
			)
		}
	}
	return (
		<Grid item xs={12} sm={12}>
			<TextField
				fullWidth
				variant="outlined"
				label={t("form.electricity")}
				name="electricity"
				type="number"
				placeholder={t("form.electricityplaceholder")}
				value={inputList.electricity[0].electricity}
				onChange={(event, value) => {
					handleInput(
						{
							target: {
								category: "electricity",
								value: event.target.value,
								name: "electricity",
								average: 0,
							},
						},
						0
					)
				}}
				margin="normal"
				InputProps={{
					endAdornment: (
						<InputAdornment position="start">
							{t("form.kwhperyear")}
						</InputAdornment>
					),
				}}
			/>
			<Typography variant="body2" color="initial">
				{t("form.dontknow")}
			</Typography>
			<Button
				fullWidth
				onClick={getCountryElecAverage}
				variant="text"
				color="default"
			>
				{t("form.dontknowbutton")}
				{values.country}
			</Button>
		</Grid>
	)
}

export default AddElectricity
