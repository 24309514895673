import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { options } from "../../../../public/data/countries";
import { useTranslation } from "react-i18next";

const AddCountry = ({ countryInput, handleChange }) => {
  const value = "";
  const { t } = useTranslation();
  return (
    <div>
      <Autocomplete
        fullWidth
        style={{ backgrounColor: "#161915" }}
        className="MuiFormControl-marginNormal"
        value={options[value]}
        inputValue={options[value]}
        onInputChange={(event, value) => {
          handleChange({
            event,
            target: {
              name: "country",
              value: value !== null ? value : null,
            },
          });
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        getOptionSelected={(option, value) => option.name === countryInput}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ backgrounColor: "#161915" }}
            variant="outlined"
            label={t("form.country.whichcountry")}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        )}
      />
    </div>
  );
};
export default AddCountry;
