import React, { useEffect, useState } from "react"
import {
	TextField,
	useMediaQuery,
	ListSubheader,
	Typography,
} from "@material-ui/core/"
import axios from "axios"
import fetch from "cross-fetch"
import Autocomplete from "@material-ui/lab/Autocomplete"
import PropTypes from "prop-types"
import { useTheme } from "@material-ui/core/styles"
import { VariableSizeList } from "react-window"

const LISTBOX_PADDING = 8 // px

function renderRow(props) {
	const { data, index, style } = props
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + LISTBOX_PADDING,
			backgroundColor: "#18302A",
		},
	})
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext)
	return (
		<div
			style="background-color:#18302A"
			ref={ref}
			{...props}
			{...outerProps}
		/>
	)
})

function useResetCache(data) {
	const ref = React.useRef(null)
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true)
		}
	}, [data])
	return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
	props,
	ref
) {
	const { children, ...other } = props
	const itemData = React.Children.toArray(children)
	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true })
	const itemCount = itemData.length
	const itemSize = smUp ? 36 : 48

	const getChildSize = (child) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48
		}

		return itemSize
	}

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
	}

	const gridRef = useResetCache(itemCount)

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	)
})

ListboxComponent.propTypes = {
	children: PropTypes.node,
}

const renderGroup = (params) => [
	<ListSubheader key={params.key} component="div">
		{params.group}
	</ListSubheader>,
	params.children,
]

const Asynchronous = ({ handleInput, x, i, name, label, icon }) => {
	const [options, setAirports] = useState([])
	const [open, setOpen] = React.useState(false)
	const loading = open && options.length === 0
	React.useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		;(async () => {
			const response = await fetch(
				"https://footprintforest.com/static/airportsdata.json",
				{
					mode: "no-cors",
				}
			)

			const options = await response.json()

			if (active) {
				setAirports(options)
			}
		})()

		return () => {
			active = false
		}
	}, [loading])

	React.useEffect(() => {
		if (!open) {
			setAirports([])
		}
	}, [open])

	return (
		<div className="flightForm">
			<Autocomplete
				fullWidth
				name={name}
				onOpen={() => {
					setOpen(true)
				}}
				onClose={() => {
					setOpen(false)
				}}
				loading={loading}
				ListboxComponent={ListboxComponent}
				renderGroup={renderGroup}
				renderOption={(option) => (
					<Typography noWrap>{option.airport}</Typography>
				)}
				onChange={(event, value) => {
					handleInput(
						{
							target: {
								name: name,
								category: "flight",
								value: value !== null ? value.airport : null,
								lat: value !== null ? value.lat : null,
								lon: value !== null ? value.lon : null,
							},
						},
						i
					)
				}}
				value={x[name][name]}
				className="asynchronous-demo"
				getOptionSelected={(option, value) => option.airport === x[name][name]}
				getOptionLabel={(option) =>
					typeof option === "string" ? option : option.airport
				}
				options={options}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						margin="normal"
						label={label}
						name={name}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							startAdornment: <React.Fragment>{icon}</React.Fragment>,
							endAdornment: (
								<React.Fragment>
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</div>
	)
}
export default Asynchronous
