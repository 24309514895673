import React, { Fragment } from "react";
import {
  Grid,
  Box,
  values,
  Typography,
  Button,
  makeStyles,
  Fade,
} from "@material-ui/core/";
import AddElectricity from "../components/mainsteps/housingsteps/AddElectricity";
import GetEmission from "../components/GetEmission";
import GetCountry from "../components/mainsteps/countrysteps/GetCountry";
import AddHousehold from "../components/mainsteps/housingsteps/AddHousehold";
import AddGreen from "../components/mainsteps/housingsteps/AddGreen";
import Seo from "../components/Seo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  bgImage: {
    backgroundImage:
      "url('https://cms.footprintforest.nl/wp-content/uploads/2020/12/electricity.jpg')",
  },
}));

const CalculatElectricity = ({
  handleChange,
  values,
  handleInput,
  handleRemoveClick,
  inputList,
  setInputList,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fade
      className={classes.bgImage + " bg-image-container"}
      in={true}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Grid container noValidate alignItems="stretch">
        <Seo
          title={t("seo.electricity.title")}
          description={t("seo.electricity.description")}
          pathSlug={t("/calculate-your-electricity")}
          keywords=""
        />

        <Grid item xs={12} sm={12}>
          <Box className="boxContainer large stretch">
            <Grid item xs={12} sm={12}>
              <Typography variant="h1" align="center">
                {t("calculateelectricity.title")}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {t("calculateelectricity.introtext")}
              </Typography>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <GetCountry countryInput={values} handleChange={handleChange} />
              </Grid>
              <Grid item xs={6}>
                <AddHousehold handleInput={handleInput} inputList={inputList} />
              </Grid>
              <AddGreen
                setInputList={setInputList}
                values={values}
                handleInput={handleInput}
                inputList={inputList}
              />
              <AddElectricity
                setInputList={setInputList}
                values={values}
                handleInput={handleInput}
                inputList={inputList}
              />
            </Grid>
            <GetEmission
              text={t("calculateelectricity.emissiontext")}
              emission={inputList.electricity[0].emission}
            />
          </Box>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default CalculatElectricity;
