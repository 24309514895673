import { Helmet } from "react-helmet-async"
import React from "react"
import i18n from "i18next"

const Seo = ({ title, description, pathSlug, keywords }) => {
	const lang = i18n.language == "en" ? "" : "/nl"
	const url = `https://www.footprintforest.com${lang}${pathSlug}`
	return (
		<Helmet
			htmlAttributes={{ lang: i18n.language }}
			title={"Footprint Forest | " + title}
			meta={[
				{
					name: "description",
					content: description,
				},
				{
					name: "keywords",
					content: keywords
						? keywords.join()
						: "footprint forest carbon calculator",
				},
				{
					propert: "og:title",
					content: "Footprint Forest | " + title,
				},
				{
					propert: "og:description",
					content: description,
				},
			]}
			link={[{ rel: "canonical", href: url }]}
		/>
	)
}
export default Seo
