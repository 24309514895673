import React from "react"
import {
	AppBar,
	IconButton,
	Toolbar,
	makeStyles,
	Box,
	Grid,
	Link,
	Button,
} from "@material-ui/core"
import FlagNL from "../img/netherlands.svg"
import FlagUK from "../img/united-kingdom.svg"
import Logo from "../img/footprintforest-logo-color.svg"
import i18n from "i18next"
import { useTranslation, withTranslation } from "react-i18next"
import { BrowserRouter as Router, withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import MoreIcon from "@material-ui/icons/MoreVert"
import SearchBar from "./SearchBar"

const useStyles = makeStyles((theme) => ({
	logo: {
		width: 150,
		padding: "12px 0 6px",
	},
	flag: {
		width: 15,
		height: 15,
	},
	appBar: {
		position: "absolute",
		background: "#ffffff",
		boxShadow: "0px 0px 0px",
	},
	navlink: {
		color: "#000000",
	},
	navlinkmobile: {
		color: "#ffffff",
	},
	grow: {
		flexGrow: 1,
		backgroundColor: "transparent",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
	inputRoot: {
		color: "inherit",
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
}))

function LanguageSelect(props) {
	const supportedLanguages = ["en", "nl"]

	const { children, t, history, match } = props
	const changeLanguage = (nextLang) => {
		i18n.changeLanguage(nextLang, () => {
			// const newUrl = localizeLink(routeName, match.params);

			const lang = nextLang == "en" ? "" : "/nl"
			const newUrl = `${lang}/${t(match.params.page)}`

			// debugger;
			history.push(newUrl)
		})
	}
	const handleClickSelectLanguage = (event, index) => {
		changeLanguage(supportedLanguages[index])
	}
	return (
		<MenuItem>
			<IconButton
				edge="end"
				aria-label="language-dutch"
				aria-haspopup="true"
				// onClick={() => setLanguage("nl")}
				onClick={(event) => handleClickSelectLanguage(event, 1)}
				color="primary"
			>
				<img
					alt="netherlands"
					style={{ width: 20, height: 20 }}
					src={FlagNL}
				></img>
			</IconButton>

			<IconButton
				edge="end"
				aria-label="language-english"
				aria-haspopup="true"
				// onClick={() => setLanguage("en")}
				onClick={(event) => handleClickSelectLanguage(event, 0)}
			>
				<img alt="english" style={{ width: 20, height: 20 }} src={FlagUK}></img>
			</IconButton>
		</MenuItem>
	)
}

// force a re-render after lang change
const LanguageSwitcher = withRouter(
	withTranslation("translations")(LanguageSelect)
)

const NavBarComp = ({ handleLang }) => {
	const { t } = useTranslation()
	const lang = i18n.language === "en" ? "" : "/nl"
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

	const isMenuOpen = Boolean(anchorEl)
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
		handleMobileMenuClose()
	}

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget)
	}

	const mobileMenuId = "primary-search-account-menu-mobile"
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<Link
					className={classes.navlinkmobile}
					component={RouterLink}
					to={`${lang}${t("/")}`}
				>
					Home
				</Link>
			</MenuItem>
			<MenuItem>
				<Link
					className={classes.navlinkmobile}
					component={RouterLink}
					to={`${lang}${t("/blog")}`}
				>
					{t("Blog")}
				</Link>
			</MenuItem>
			<MenuItem>
				<Link
					className={classes.navlinkmobile}
					component={RouterLink}
					to={`${lang}${t("/about-us")}`}
				>
					{t("About us")}
				</Link>
			</MenuItem>

			<MenuItem>
				<Link
					className={classes.navlinkmobile}
					component={RouterLink}
					to={`${lang}${t("/footprint-calculator")}`}
				>
					{t("Footprint Calculator")}
				</Link>
			</MenuItem>

			<LanguageSwitcher />
		</Menu>
	)

	return (
		<div className={classes.grow}>
			<AppBar component="header" className={classes.appBar} position="static">
				<Toolbar component="nav">
					<Link component={RouterLink} to={`${lang}${t("/")}`}>
						<img
							width="150px"
							height="75px"
							alt="logo"
							className={classes.logo}
							src={Logo}
						/>
					</Link>
					<Grid item sm={1} xs={1}></Grid>
					<Box display={{ xs: "none", sm: "block" }}>
						<Grid container>
							<Grid item sm={12} xs={6} className="navSearch">
								<SearchBar
									dark={true}
									variant="outlined"
									color="primary"
									label={t("searchbar.placeholder")}
								/>
							</Grid>
						</Grid>
					</Box>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop} role="menu">
						<MenuItem>
							<Button
								variant="text"
								className={classes.navlink}
								component={RouterLink}
								to={`${lang}${t("/")}`}
							>
								Home
							</Button>
						</MenuItem>
						<MenuItem>
							<Button
								variant="text"
								className={classes.navlink}
								component={RouterLink}
								to={`${lang}${t("/blog")}`}
							>
								{t("Blog")}
							</Button>
						</MenuItem>
						<MenuItem>
							<Button
								variant="text"
								className={classes.navlink}
								component={RouterLink}
								to={`${lang}${t("/about-us")}`}
							>
								{t("About us")}
							</Button>
						</MenuItem>
						<MenuItem>
							<Button
								variant="text"
								className={classes.navlink}
								component={RouterLink}
								to={`${lang}${t("/footprint-calculator")}`}
							>
								{t("Footprint Calculator")}
							</Button>
						</MenuItem>

						<LanguageSwitcher />
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="secondary"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
		</div>
	)
}

const NavBar = withTranslation()(NavBarComp)

export default NavBar
