import React from "react";
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from "@material-ui/core/";
import { useTranslation } from "react-i18next";

const AddGreen = ({ handleInput, inputList }) => {
  const { t } = useTranslation();

  return (
    <FormControl compnent="fieldset" fullWidth required margin="normal">
      <FormLabel component="legend">{t("form.greenenergy")}</FormLabel>
      <RadioGroup
        value={inputList.electricity[0].green}
        onChange={(event, value) => {
          handleInput(
            {
              target: {
                category: "electricity",
                value: event.target.value,
                name: "green",
              },
            },
            0
          );
        }}
        label={t("form.greenenergy")}
        name="green"
        category="electricity"
      >
        <FormControlLabel
          value={"green0"}
          control={<Radio />}
          label={t("form.greenoption1")}
        />
        <FormControlLabel
          value={"green50"}
          control={<Radio />}
          label={t("form.greenoption2")}
        />
        <FormControlLabel
          value={"green75"}
          control={<Radio />}
          label={t("form.greenoption3")}
        />
        <FormControlLabel
          value={"green100"}
          control={<Radio />}
          label={t("form.greenoption4")}
        />
      </RadioGroup>
    </FormControl>
  );
};
export default AddGreen;
