import React, { Fragment, useState } from "react"
import { TextField, Icon, makeStyles } from "@material-ui/core/"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

export function getPages() {}

const SearchBar = ({ variant, color, label, dark }) => {
	const [searchfield, setSearchField] = useState({
		searchinput: null,
	})
	const history = useHistory()
	function goTo(value) {
		if (value != null) {
			history.push(value.url)
		}
	}
	const { t } = useTranslation()

	const lang = i18n.language == "en" ? "" : "/nl"
	const baseUrl = `${lang}`
	const allPages = [
		{
			title: t("terms.Flight Emissions"),
			type: t("terms.Transport"),
			url: lang + t("/calculate-your-flights"),
			icon: "flight_takeoff",
		},
		{
			title: t("terms.Car Emissions"),
			type: t("terms.Transport"),
			url: lang + t("/calculate-your-car"),
			icon: "drive_eta",
		},
		{
			title: t("terms.Public Transport Emissions"),
			type: t("terms.Transport"),
			url: lang + t("/calculate-your-public-transport"),
			icon: "commute_rounded",
		},
		{
			title: t("terms.Motorbike Emissions"),
			type: t("terms.Transport"),
			url: lang + t("/calculate-your-motorbike"),
			icon: "motorcycle",
		},
		{
			title: t("terms.Electricity Emissions"),
			type: t("terms.Housing"),
			url: lang + t("/calculate-your-electricity"),
			icon: "power",
		},
		{
			title: t("terms.Gas Emissions"),
			type: t("terms.Housing"),
			url: lang + t("/calculate-your-gas"),
			icon: "local_gas_station",
		},
		{
			title: t("terms.Diet's Emissions"),
			type: t("terms.Diet"),
			url: lang + t("/calculate-your-diets-co2-emissions"),
			icon: "fastfood",
		},
		{
			title: t("terms.Footprint Calculator"),
			type: t("terms.General"),
			url: lang + t("/footprint-calculator"),
			icon: "question_answer",
		},
	]

	const useStyles = makeStyles((theme) => ({
		small: {
			paddingLeft: 5,
			fontStyle: "italic",
		},
		icon: {
			paddingRight: 10,
		},
		searchBar: {
			background: "rgba(255,255,255,0.2)",
		},
	}))
	const classes = useStyles()

	return (
		<div>
			<Autocomplete
				fullWidth
				color={color}
				value={searchfield.searchinput}
				onChange={(event, newValue) => {
					goTo(newValue)
				}}
				options={allPages}
				getOptionLabel={(option) => option.title}
				renderOption={(option) => {
					return (
						<Fragment>
							<span className={classes.icon}>
								<Icon>{option.icon}</Icon>
							</span>
							{option.title}{" "}
						</Fragment>
					)
				}}
				filterSelectedOptions
				renderInput={(params) => (
					<Fragment>
						<TextField
							{...params}
							variant={variant}
							color={color}
							style={
								dark === true
									? {
											backgroundColor: "#ededed",
											minWidth: 250,
											borderRadius: 5,
									  }
									: {
											backgroundColor: "rgba(255,255,255,0.2)",
									  }
							}
							label={label}
							placeholder="Select a calculator"
							margin="none"
							fullWidth
						/>
					</Fragment>
				)}
			/>
		</div>
	)
}

export default SearchBar
