import React from "react";
import Autocomplete from "./Autocomplete";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import { useTranslation } from "react-i18next";

const AddFlight = ({
  handleInput,
  handleRemoveClick,
  handleAddClick,
  inputList,
  fromAirport,
  toAirport,
  name,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container noValidate>
      {inputList.flight.map((x, i) => {
        return (
          <Grid container key={i} spacing={3} noValidate alignItems="flex-end">
            <Grid item xs={6} sm={2}>
              <FormControl
                fullWidth
                variant="outlined"
                required
                margin="normal"
              >
                <InputLabel>{t("form.type")}</InputLabel>
                <Select
                  label={t("form.type")}
                  name="flightType"
                  value={x["flightType"]}
                  onChange={(event, value) => {
                    handleInput(
                      {
                        target: {
                          category: "flight",
                          value: value !== null ? value.props.value : null,
                          name: "flightType",
                        },
                      },
                      i
                    );
                  }}
                >
                  <MenuItem value={1}>{t("form.oneway")}</MenuItem>
                  <MenuItem value={2}>{t("form.return")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                margin="normal"
              >
                <InputLabel htmlFor="cabinClass">{t("form.class")}</InputLabel>
                <Select
                  label={t("form.class")}
                  name="cabinClass"
                  value={x["cabinClass"]}
                  onChange={(event, value) => {
                    handleInput(
                      {
                        target: {
                          category: "flight",
                          value: value !== null ? value.props.value : null,
                          name: "cabinClass",
                        },
                      },
                      i
                    );
                  }}
                >
                  <MenuItem value={"economy-class"}>Economy class</MenuItem>
                  <MenuItem value={"premium-economy-class"}>
                    Premium economy class
                  </MenuItem>
                  <MenuItem value={"business-class"}>Business class</MenuItem>
                  <MenuItem value={"first-class"}>First class</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                handleInput={handleInput}
                x={x}
                i={i}
                key="fromAirport"
                name="fromAirport"
                label={t("form.from")}
                value={fromAirport}
                inputList={inputList}
                icon={<FlightTakeoffIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                handleInput={handleInput}
                x={x}
                i={i}
                key="toAirport"
                name="toAirport"
                label={t("form.to")}
                value={toAirport}
                inputList={inputList}
                icon={<FlightLandIcon />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <div
                className="btn-box"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {inputList.flight.length !== 1 && (
                  <Button
                    className="mr10"
                    color="secondary"
                    variant="text"
                    size="small"
                    value="removeFlight"
                    startIcon={<DeleteIcon />}
                    onClick={(e) => handleRemoveClick(e, i)}
                  >
                    {t("form.remove")}
                  </Button>
                )}
                {inputList.flight.length - 1 === i && (
                  <Button
                    color="primary"
                    value="addFlight"
                    name="addFlight"
                    startIcon={<AddIcon />}
                    variant="text"
                    size="small"
                    onClick={(e) => handleAddClick(e, value)}
                  >
                    {t("form.addflight")}
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AddFlight;
