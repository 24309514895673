import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import HomeIcon from "@material-ui/icons/Home";
import { useTranslation } from "react-i18next";

const AddHousehold = ({ handleInput, inputList }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <TextField
        fullWidth
        variant="outlined"
        label={t("form.household")}
        name="household"
        type="number"
        placeholder={t("form.householdsize")}
        value={inputList.electricity[0].household}
        onChange={(event, value) => {
          handleInput(
            {
              target: {
                category: "electricity",
                value: event.target.value,
                name: "household",
              },
            },
            0
          );
        }}
        margin="normal"
        InputProps={{
          startAdornment: (
            <React.Fragment>
              <HomeIcon />
            </React.Fragment>
          ),
          endAdornment: (
            <InputAdornment position="start">{t("form.people")}</InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default AddHousehold;
