import React from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core/";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import SearchBar from "../components/SearchBar";

export default ({ staticContext = {} }) => {
  staticContext.status = 404;
  const { t } = useTranslation();
  return (
    <Box className="boxContainer home loading stretch trees">
      <Grid item align="center" xs={12} sm={12}>
        <Box>
          <Typography variant="h1" component="h1" align="center">
            {t("notfound.title")}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            {t("notfound.intro")}
          </Typography>
          <SearchBar
            variant="outlined"
            color="primary"
            label={t("searchbar.placeholder")}
          />
          <br />
          <Button
            component={RouterLink}
            to="/"
            size="large"
            variant="contained"
            // disabled={!isEmpty || isError}
            color="primary"
          >
            {t("notfound.button")}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};
