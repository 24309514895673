import React from "react";
import {
  Paper,
  Grow,
  Box,
  Icon,
  Typography,
  makeStyles,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    color: theme.palette.greenDark.main,
    margin: "20px auto 0",
    position: "relative",
    zIndex: 2,
    maxWidth: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const GetEmission = ({ text, emission }) => {
  const classes = useStyles();

  return (
    <Grow
      in={emission === "" ? false : true}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Paper className={classes.paper}>
        <Box align="center" p={10}>
          <Typography variant="body1">Result:</Typography>
          <Typography variant="h3">{Math.round(emission)} Kg Co2</Typography>
          <Typography variant="body1">{text}</Typography>
        </Box>
      </Paper>
    </Grow>
  );
};

export default GetEmission;
